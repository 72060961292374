// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-page-template-jsx": () => import("/opt/build/repo/src/templates/BlogPageTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-page-template-jsx" */),
  "component---src-templates-gallery-page-template-jsx": () => import("/opt/build/repo/src/templates/GalleryPageTemplate.jsx" /* webpackChunkName: "component---src-templates-gallery-page-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("/opt/build/repo/src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("/opt/build/repo/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

